<script setup lang="ts">
import {
    CmsElementImage,
    CmsElementManufacturerLogo,
    useCmsElementImage,
} from "@shopware-pwa/composables-next";

//todo: implement support for display modes "cover" and "stretch"
const props = defineProps<{
    content: CmsElementImage | CmsElementManufacturerLogo;
}>();

const {
    containerStyle,
    displayMode,
    imageContainerAttrs,
    imageAttrs,
    imageLink,
} = useCmsElementImage(props.content);

if (!imageAttrs.value.src) {
    imageAttrs.value.src = "/img_placeholder.jpg";
}
</script>
<template>
<component
    v-if="imageAttrs.src"
    class="cms-element-image relative overflow-hidden max-w-full"
    :is="imageLink.url ? 'a' : 'div'"
    :style="(displayMode === 'standard') ? '300px' : containerStyle"
    v-bind="imageContainerAttrs"
>
    <div class="img-parent-div">
        <NuxtImg
            :class="{
                'w-full': true,
              }"
            v-bind="imageAttrs"
            alt="Image link"
            loading="lazy"
        />
    </div>
</component>
</template>
